import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


import "../src/assets/css/animate.css";
import "../src/assets/css/owl.carousel.css";
import "../src/assets/css/owl.theme.css";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/hover-min.css";
import "../src/assets/css/flag-icon.min.css";
import "../src/assets/css/style.css";
import "../src/assets/css/elegant_icon.css";
import "../src/assets/css/style.css";
import "../src/assets/fonts/font-awesome/css/font-awesome.min.css";
// import "../src/assets/js/YouTubePopUp.jquery.js";
// import "../src/assets/js/owl.carousel.min.js";
// import "../src/assets/js/imagesloaded.min.js";
// import "../src/assets/js/popper.min.js";
// import "../src/assets/js/bootstrap.min.js";
// pinia

import { createI18n } from 'vue-i18n'
const i18n = createI18n({
    // something vue-i18n options here ...
  })

import { createPinia } from 'pinia'
const pinia = createPinia()
// axios
import axios from 'axios'
import VueAxios from 'vue-axios'


createApp(App).use(router).use(pinia).use(VueAxios, axios).use(i18n).mount('#app')

