<template> 
    <footer  class="layout-dark text-left">
        <div class="container padding-tb-100px">
            <div class="row">

                <div class="col-lg-3 col-md-6">
                    
                    <div class="about-us sm-mb-45px">
                        <div class="logo-footer margin-bottom-35px">
                            <a href="#"><img src="../assets/img/logo-wide.png" alt=""></a>
                        </div>

                        <div class="text margin-bottom-35px" :class="lang === 'en'  ? '' : 'text-right'">
                            <span >{{aboutCompany}} </span>

                        </div>
                        <!-- <router-link to="/about" class="nile-bottom sm">Read More</router-link> -->
                    </div>
                </div>
                <div class="col-lg-3 col-md-6"  :class="lang === 'en'  ? '' : 'text-right'">
                    <div class="nile-widget widget_nav_menu sm-mb-45px">
                        <h2 class="title">
                            
                            <span v-if="lang === 'en'"> Our Services </span>
                           <span  v-else-if="lang === 'kr'"> خزمەت گوزاریەکانمان  </span>
                           <span  v-else> خدماتنا  </span>
                        </h2>
                        <ul class="footer-menu"  :class="lang === 'en'  ? '' : 'text-right'">
                            <!-- <li v-for="item in services" :key="item.id"><a href="#">{{item.title}}</a></li> -->
                            <li><router-link v-for="item in services" :key="item.id" :to="{ name: 'singleServices', params: { id: `${item.id}` } }">{{item.title}}</router-link></li>

                        </ul>
                    </div>
                </div>


                <div class="col-lg-3 col-md-6"  :class="lang === 'en'  ? '' : 'text-right'">
                    <div class="nile-widget widget_nav_menu sm-mb-45px">
                        <h2 class="title">
                            <span v-if="lang === 'en'"> Site Pages </span>
                           <span  v-else-if="lang === 'kr'"> لاپەڕەکانی ماڵپەڕ </span>
                           <span  v-else> صفحات الموقع </span>
                        </h2>
                        <ul class="footer-menu " :class="lang === 'en' ? '' : 'ar'">
                            <li><router-link to="/"><span v-if="lang === 'en'">Home</span> <span v-else-if="lang === 'kr'">سەرەکی</span> <span v-else>الرئيسية</span></router-link></li>
                            <li><a href="/#About"><span v-if="lang === 'en'">About</span> <span v-else-if="lang === 'kr'">دەربارەی کۆمپانیا</span> <span v-else>عن الشركة</span></a></li>
                            <li><a href="/#Service"><span v-if="lang === 'en'">services</span> <span v-else-if="lang === 'kr'">خزمەتگوزارییەکان</span> <span v-else>الخدمات</span></a></li>
                            <li> <router-link to="/team"><span v-if="lang === 'en'">Team</span> <span v-else-if="lang === 'kr'">تیم</span> <span v-else>الفريق</span></router-link></li>
                            <li><router-link to="/contact"><span v-if="lang === 'en'">contact</span> <span v-else-if="lang === 'kr'">پەیوەندیکردن</span> <span v-else>تواصل معنا</span></router-link></li>
                            <li><router-link to="/blog"><span v-if="lang === 'en'">blog</span> <span v-else-if="lang === 'kr'">بابەتەکان</span> <span v-else>المقالات</span></router-link></li>


                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6" :class="lang === 'en' ? '' : 'text-right'">
                    
                    <div class="nile-widget">
                        <div class="margin-bottom-60px">
                            <h2 class="title">
                                <span v-if="lang === 'en'"> Location </span>
                                <span v-else-if="lang === 'kr'"> سايتەكە : </span>
                                <span  v-else>  الموقع </span>
                            </h2>
                            <div class="contact-info opacity-9">
                                <div class="margin-top-5px"  :class="lang === 'en' ? 'icon' : 'icon-ar'"> <i class="fa fa-map-marker" aria-hidden="true"></i></div>
                                <div class="text">
                                    <span class="title-in">
                                        <span v-if="lang === 'en'"> Location :</span>
                                        <span v-else-if="lang === 'kr'"> سايتەكە : </span>
                                        <span  v-else> الموقع : </span>
                                    </span> <br>

                                    <span class="font-weight-500 text-uppercase">{{ location }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="call_center">
                            <h2 class="title">
                                
                                <span v-if="lang === 'en'"> Call Center :</span>
                                <span v-else-if="lang === 'kr'"> پەیوەندیمان پێوە بکەن : </span>
                                <span  v-else> اتصل بنا : </span>
                            </h2>
                            <div class="contact-info opacity-9">
                                <div class=" margin-top-5px"  :class="lang === 'en'  ? 'icon' : 'icon-ar'"><i class="fa fa-phone" aria-hidden="true"></i></div>
                                <div class="text">
                                    <span class="title-in">
                                        <span v-if="lang === 'en'"> Call Us :</span>
                                        <span v-else-if="lang === 'kr'"> پەیوەندیمان پێوە بکەن : </span>
                                        <span  v-else> اتصل بنا : </span>
                                    </span><br>
                                    <span class="font-weight-500 text-uppercase">{{ phone }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        
        <div class="copy-right">
            <div class="container padding-tb-50px">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="copy-right-text text-center sm-mb-15px" :class="lang === 'en' ? 'text-lg-left' : 'text-lg-right'"><span v-if="lang === 'en'">Copyright</span> <span v-else-if="lang === 'kr'">   هەموو مافەکان ڕزگار دەکرێن </span> <span v-else>جميع الحقوق محفوظة</span> </div>
                    </div>
                    <div class="col-lg-6">
                        <!--  Social -->
                        <ul class="social-media list-inline  text-center margin-0px text-white" :class="lang === 'en' ? 'text-lg-right' : 'text-lg-left'">

                            <li class="list-inline-item"><a class="facebook" href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                            <li class="list-inline-item"><a class="linkedin" href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                        </ul>
                        <!-- // Social -->
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script setup>
import {onMounted , ref } from 'vue'
 // store
 import { useHome } from "../../src/views/home/store"
  import { storeToRefs } from "pinia"
  const aboutCompany = ref('')
  const location = ref('')
  const phone = ref('')

  const store = useHome()

  const { services  , contactInfoConst } = storeToRefs(store)
 

  const lang = ref('')
  lang.value=  localStorage.getItem('lang') 
  
  onMounted( async() => {
    
    await store.fetchContactInfo() 
    console.log(lang , "lang frpm footer")
    if (lang.value=== null){
        lang.value = 'en'
    }

    aboutCompany.value =  contactInfoConst.value.filter(item => item.slug === "about-the-company")[0].value
    location.value =  contactInfoConst.value.filter(item => item.slug === "address")[0].value
    phone.value =  contactInfoConst.value.filter(item => item.slug === "mobile-number")[0].value
   })
</script>
<style>
.ar a:before {
    content: "\34" !important;
    font-family: 'ElegantIcons';
    color: #fff;
    display: inline-block;
    margin-right: 5px;
}
footer.layout-dark .contact-info .icon-ar {
    float: right !important;
    font-size: 30px !important;
    margin-left: 15px !important;
}
</style>
