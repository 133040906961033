<template>
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
<div class="carousel-inner">
  <div>
    
  </div>
  <div v-for="(item , index) in sliders" :key="item.id" class="carousel-item " :class="index===0 ? 'active' : ''">
    <img class="d-block w-100" :src="item.image" alt="First slide">
    <div class="carousel-caption d-none d-md-block">
      <p>{{item.caption.en}}</p>
    </div>
    
  </div>
</div>
<!-- <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
  <span class="sr-only">Previous</span>
</a>
<a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
  <span class="carousel-control-next-icon" aria-hidden="true"></span>
  <span class="sr-only">Next</span>
</a> -->
</div>
</template>
<script setup>
// store
import { useHome } from "../store"
import { storeToRefs } from "pinia"

const store = useHome()

const { sliders  } = storeToRefs(store)
</script>
<style>
.carousel-item img{
height: 900px;
  object-fit: cover;

}
.carousel-item::after{
  content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 40%);
}
.carousel-caption {
  position: absolute;
  top: 25%;
  left: 9% !important;
  color: #fff;
  text-align: left;
}
.carousel-caption p{
  min-width: 300px;
    max-width: 300px;
    white-space: wrap;
    font-size: 50px;
    line-height: 59px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    letter-spacing: -4px;
    font-family: Poppins;
    visibility: inherit;
    text-align: left;
    margin: 0px;
    padding: 0px;
}
</style>

