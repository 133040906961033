<template>
  <!-- {{ loading }}
  <div v-if="loading">

  </div> -->
    <div  class="home">
      <!-- // Get A Quote  -->
      <SliderComponents></SliderComponents>
      <SearchComonent></SearchComonent>
      <!-- <TrackView v-if="Object.keys(shipment).length !== 0"></TrackView> -->

      <AboutComponent></AboutComponent>
      <ServicesComponent></ServicesComponent>
      <ArticalComponent></ArticalComponent>
        
   
  </div>
    
</template>

  <script setup>
  import SliderComponents from "./components/SliderComponents.vue"
  import SearchComonent from "./components/SearchComonent.vue"
  // import TrackView from "@/views/track/TrackView.vue"

  import AboutComponent from "./components/AboutComponent.vue"
  import ServicesComponent from "./components/ServicesComponent.vue"
  import ArticalComponent from "./components/ArticalComponent.vue"
  import { onMounted } from 'vue'
  // store
  import { useHome } from "./store"

  const store = useHome()
  
  // import { storeToRefs } from "pinia"
  // const { loading } = storeToRefs(store)

  // import { useTrack  } from "@/views/track/store"
  // import { storeToRefs } from "pinia"

  // const storeTrack = useTrack()

  // const { shipment } = storeToRefs(storeTrack)

  onMounted(async() => {
    await store.fetchInfo()
    await store.fetchContactInfo() 
   })
  </script>
  
  
  