<template>
    <!--  Header  -->
	<header>
        <div id="fixed-header-dark" class="header-output fixed-header" >
            <div class="header-output">
                <div class="container header-in">
                    <!-- Up Head -->
                    <div class="up-head d-none d-lg-block">
                        <div class="row">
                            <div class="col-xl-8 col-lg-12">
                                <div class="row">
                                    <div class="col-md-4"><i class="fa fa-phone margin-right-10px"></i>{{phone}}</div>
                                    <div class="col-md-4"><i class="fa fa-envelope-o margin-right-10px"></i> {{email}}</div>
                                    <div class="col-md-4"><i class="fa fa-map-marker margin-right-10px"></i> {{location}}</div>
                                </div>
                            </div>
                            <div class="col-xl-4 d-none d-xl-block">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <!-- lang dropdown -->
                                        <div v-if="lang==='en'" class="dropdown show">
                                            <a class="dropdown-toggle text-white text-uppercase" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span class="flag-icon flag-icon-us margin-right-8px"></span> English
                                            </a>

                                            <div class="dropdown-menu text-small text-uppercase" aria-labelledby="dropdownMenuLink">

                                                <btn class="dropdown-item" @Click="handelLang('ar')"><span class="flag-icon flag-icon-mr margin-right-8px"></span> Arabic</btn>
                                                <btn class="dropdown-item" @Click="handelLang('kr')"> <span class="flag-icon flag-icon-us margin-right-8px"></span> Kurdish</btn>
                                            </div>
                                        </div>
                                        <!-- // lang dropdown -->
                                         <!-- lang dropdown -->
                                         <div v-else-if="lang==='ar'"  class="dropdown show">
                                            <a class="dropdown-toggle text-white text-uppercase" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span class="flag-icon flag-icon-mr margin-right-8px"></span> Arabic
                                            </a>

                                            <div class="dropdown-menu text-small text-uppercase" aria-labelledby="dropdownMenuLink">

                                                <btn class="dropdown-item" @Click="handelLang('en')"> <span class="flag-icon flag-icon-us margin-right-8px"></span> English</btn>
                                                <btn class="dropdown-item" @Click="handelLang('kr')"> <span class="flag-icon flag-icon-us margin-right-8px"></span> Kurdish</btn>
                                            </div>
                                        </div>
                                        <!-- // lang dropdown -->
                                        <!-- lang dropdown -->
                                        <div v-else  class="dropdown show">
                                            <a class="dropdown-toggle text-white text-uppercase" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span class="flag-icon flag-icon-mr margin-right-8px"></span> Kurdish
                                            </a>

                                            <div class="dropdown-menu text-small text-uppercase" aria-labelledby="dropdownMenuLink">

                                                <btn class="dropdown-item" @Click="handelLang('en')"> <span class="flag-icon flag-icon-us margin-right-8px"></span> English</btn>
                                                <btn class="dropdown-item" @Click="handelLang('ar')"><span class="flag-icon flag-icon-mr margin-right-8px"></span> Arabic</btn>
                                            </div>
                                        </div>
                                        <!-- // lang dropdown -->

                                    </div>

                                    <div class="col-lg-6">
                                        <!--  Social -->
                                        <ul class="social-media list-inline text-right margin-0px text-white">
                                            <li class="list-inline-item"><a class="facebook" href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                            <li class="list-inline-item"><a class="linkedin" href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        </ul>
                                        <!-- // Social -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- // Up Head -->

                    <div class="position-relative">
                        <div class="row">
                            <div class="col-lg-2 col-md-12 col-push-1">
                                <div class="d-flex titleNav">
                                    <router-link to="/"  class="d-inline-block margin-tb-15px"><img src="../assets/img/logo-wide.png" alt=""></router-link>
                                    <!-- <a id="logo" href="home-1.html" class="d-inline-block margin-tb-15px"><img src="../assets/img/logo-wide.png" alt=""></a> -->
                                <!-- <a class="mobile-toggle padding-15px background-second-color border-radius-3" href="#"><i class="fa fa-bars"></i></a> -->
                                <!-- <div class="dropdown ">
                                    <button class="btn smallScreen btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                       
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <p class="text-center"><router-link to="/"><span v-if="lang==='en'">Home</span> <span v-else-if="lang==='kr'">سەرەکی</span> <span v-else>الرئيسية</span></router-link></p>
                                        <p class="text-center"><a href="/#About"><span v-if="lang==='en'">About</span> <span v-else-if="lang==='kr'"> دەربارەی کۆمپانیا </span>  <span v-else>عن الشركة</span></a></p>
                                        <p class="text-center"><a href="/#Service"><span v-if="lang==='en'">services</span> <span v-else-if="lang==='kr'">خزمەتگوزارییەکان</span>  <span v-else>الخدمات</span></a></p>
                                        <p class="text-center"><router-link to="/team"><span v-if="lang==='en'">Team</span> <span v-else-if="lang==='kr'">تیم</span>  <span v-else>الفريق </span></router-link></p>
                                        <p class="text-center"><router-link to="/contact"><span v-if="lang==='en'">contact</span> <span v-else-if="lang==='kr'">پەیوەندیکردن</span>  <span v-else>تواصل معنا</span></router-link></p>
                                        <p class="text-center"><router-link to="/blog"><span v-if="lang==='en'">blog</span><span v-else-if="lang==='kr'">بابەتەکان</span>  <span v-else> المقالات</span></router-link></p>
                                        <p class="col-lg-2 col-md-12  d-none d-lg-block">
                                            <a data-toggle="modal" data-target=".bd-example-modal-lg" href="#" class="btn btn-sm border-radius-30 margin-tb-20px text-white  background-grey-4  box-shadow float-right padding-lr-20px margin-left-30px d-block">
                                                <i class="fa fa-user margin-right-10px"></i> <span v-if="lang==='en'">Account login</span> <span v-else> تسجيل دخول</span>  
                                            </a>
                                        </p>
                                        <p class="col-lg-2 col-md-12  d-none d-lg-block">
                                            <a data-toggle="modal" data-target=".bd-example-modal-lg" href="#" class="btn btn-sm border-radius-30 margin-tb-20px text-white  background-main-color  box-shadow float-right padding-lr-20px margin-left-30px d-block">
                                            <i class="fa fa-envelope-o margin-right-10px"></i>  <span v-if="lang==='en'"> Get a Price Offer </span> <span v-else> طلب عرض سعر </span> 
                                            </a>
                                        </p>
                                    </div>
                                </div> -->

                                </div>
                                
                            </div>
                            <div class="col-lg-6 col-md-12 position-inherit">
                                <ul id="menu-main" class="nav-menu float-xl-left text-lg-center link-padding-tb-25px white-link dropdown-dark">
                                    <li><router-link to="/"><span v-if="lang==='en'">Home</span>  <span v-else-if="lang==='kr'">سەرەکی</span>  <span v-else>الرئيسية</span></router-link></li>
                                    <li><a href="/#About"><span v-if="lang==='en'">About</span>  <span v-else-if="lang==='kr'"> دەربارەی کۆمپانیا </span>  <span v-else>عن الشركة</span></a></li>
                                    <li><a href="/#Service"><span v-if="lang==='en'">services</span>  <span v-else-if="lang==='kr'">خزمەتگوزارییەکان</span> <span v-else>الخدمات</span></a></li>
                                    <li> <router-link to="/team"><span v-if="lang==='en'">Team</span>  <span v-else-if="lang==='kr'">تیم</span>  <span v-else>الفريق </span></router-link></li>
                                    <li><router-link to="/contact"><span v-if="lang==='en'">contact</span>  <span v-else-if="lang==='kr'">پەیوەندیکردن</span> <span v-else>تواصل معنا</span></router-link></li>
                                    <li class="mx-3"><router-link to="/blog"><span v-if="lang==='en'">blog</span> <span v-else-if="lang==='kr'">بابەتەکان</span>  <span v-else> المقالات</span></router-link></li>
                                </ul>
                            </div>
                            <div class="col-lg-2 col-md-12  d-none d-lg-block">
                                <a data-toggle="modal" data-target=".bd-example-modal-lg" href="#" class="btn btn-sm border-radius-30 margin-tb-20px text-white  background-grey-4  box-shadow float-right padding-lr-20px margin-left-30px d-block">
                                    <i class="fa fa-user margin-right-10px"></i> <span v-if="lang==='en'">Account login</span>  <span v-else-if="lang==='kr'">چونه‌ ژووره‌وه‌</span>   <span v-else> تسجيل دخول</span>  
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-12  d-none d-lg-block">
                                <a data-toggle="modal" data-target=".bd-example-modal-lg" href="#" class="btn btn-sm border-radius-30 margin-tb-20px text-white  background-main-color  box-shadow float-right padding-lr-20px margin-left-30px d-block">
                                <i class="fa fa-envelope-o margin-right-10px"></i>  <span v-if="lang==='en'"> Get a Price Offer </span>  <span v-else-if="lang==='kr'">خزمەتگوزارییەکان</span>  <span v-else> طلب عرض السعر </span> 
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- // Header  -->
     <!-- Get A Quote  -->
     <div class="modal contact-modal fade bd-example-modal-lg text-left" tabindex="-1" role="dialog" aria-hidden="true"  :class="lang==='en' ? '' : 'text-right'">
        <div class="modal-dialog modal-lg">
            <div class="modal-content margin-top-150px background-main-color">
                <div class="row no-gutters">
                    <!-- <div class="col-lg-5">
                        <img src="../assets/img/contact-img.jpg" alt="">
                    </div> -->
                    <div class="col-lg-12">
                        <div class="padding-30px">
                            <h3 class="padding-bottom-15px">  <span v-if="lang==='en'"> Get a Price Offer </span>  <span v-else-if="lang==='kr'">خزمەتگوزارییەکان</span> <span v-else> طلب عرض سعر </span> </h3>
                            <hr>
                            <form>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Service Type</span>  <span v-else-if="lang==='kr'">جۆری خزمەتگوزاری</span> <span v-else> نوع الخدمة</span></label>
                                        <select class="form-control" v-model="localData.service_type">
                                            <option value="partial">  <span v-if="lang==='en'"> partial</span>  <span v-else-if="lang==='kr'">گەیاندنی بەشەکی</span> <span v-else> شحن جزئي</span></option>
                                            <option value="container"> <span v-if="lang==='en'"> container </span>  <span v-else-if="lang==='kr'">گەیاندنی کۆنتێنەر</span> <span v-else> شحن حاويات</span></option>
                                        </select>
                                        <span v-if="validServiceType"><span v-if="lang==='en'">this feild is required</span>  <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Source</span>  <span v-else-if="lang==='kr'">سەرچاوە</span> <span v-else>المصدر</span></label>
                                        <select class="form-control" v-model="localData.office_source_id">
                                            <option v-for="item in offices" :key="item.id" :value="item.id">{{ item.name }}</option>
                                        </select>
                                        <span v-if="validSource"><span v-if="lang==='en'">this feild is required</span>  <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Destination</span>  <span v-else-if="lang==='kr'">جێمەبەست</span> <span v-else>الوجهة</span></label>
                                        <select class="form-control"  v-model="localData.office_destination_id">
                                            <option v-for="item in offices" :key="item.id" :value="item.id">{{ item.name }}</option>
                                        </select>
                                        <span v-if="validDestination"><span v-if="lang==='en'">this feild is required</span>  <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Are You From Our Customers</span>  <span v-else-if="lang==='kr'">ئایا تۆ یەکێکیت لە کڕیارەکانمان؟</span>  <span v-else> هل أنت من عملائنا</span></label>
                                        <select class="form-control"  v-model="localData.our_member">
                                            <option value="1">yes</option>
                                            <option value="0">no</option>
                                        </select>
                                        <span v-if="validMember"><span v-if="lang==='en'">this feild is required</span>  <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Stuff Type</span>  <span v-else-if="lang==='kr'">جۆری بار</span> <span v-else> نوع الحمولة</span></label>
                                        <input type="text" class="form-control" v-model="localData.stuff_type">
                                        <span v-if="validStuffType"><span v-if="lang==='en'">this feild is required</span> <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                        
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Stuff Weight</span>  <span v-else-if="lang==='kr'">کێشی بار</span> <span v-else> وزن الحمولة</span></label>
                                        <input type="text" class="form-control" v-model="localData.stuff_weight">
                                        <span v-if="validStuffWeight"><span v-if="lang==='en'">this feild is required</span>  <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Stuff Size</span>  <span v-else-if="lang==='kr'">قەبارەی بار</span>  <span v-else> حجم الحمولة</span></label>
                                        <input type="text" class="form-control" v-model="localData.stuff_size">
                                        <span v-if="validStuffSize"><span v-if="lang==='en'">this feild is required</span>  <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Loading Place</span>  <span v-else-if="lang==='kr'">شوێنی داگرتن</span>  <span v-else> مكان التحميل</span></label>
                                        <input type="text" class="form-control" v-model="localData.loading_place">
                                        <span v-if="validLoadingPlace"><span v-if="lang==='en'">this feild is required</span> <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Details</span>  <span v-else-if="lang==='kr'">وردەکارییەکان</span> <span v-else> التفاصيل </span></label>
                                        <textarea class="form-control" v-model="localData.details" rows="1"></textarea>
                                        <span v-if="validDetails"><span v-if="lang==='en'">this feild is required</span>  <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Client Name</span>  <span v-else-if="lang==='kr'">ناوي كريار</span> <span v-else> اسم العميل</span></label>
                                        <input type="text" class="form-control" v-model="localData.client_name">
                                        <span v-if="validClientName"><span v-if="lang==='en'">this feild is required</span>  <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Company Name</span>  <span v-else-if="lang==='kr'">ناوی كۆمپانیا</span> <span v-else> اسم الشركة</span></label>
                                        <input type="text" class="form-control" v-model="localData.company_name">
                                        <span v-if="validCompanyName"><span v-if="lang==='en'">this feild is required</span>  <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Email</span>  <span v-else-if="lang==='kr'">ئیمەیڵ</span>  <span v-else> البريد الإلكتروني</span></label>
                                        <input type="email" class="form-control" v-model="localData.email">
                                        <span v-if="validEmail"><span v-if="lang==='en'">this feild is required</span>  <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Mobile</span> <span v-else-if="lang==='kr'">ژماره‌ی مۆبایل</span> <span v-else> رقم الموبايل</span></label>
                                        <input type="text" class="form-control" v-model="localData.mobile">
                                        <span v-if="validMobile"><span v-if="lang==='en'">this feild is required</span> <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span><span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Shipping Date</span>  <span v-else-if="lang==='kr'">بەرواری بارکردن</span> <span v-else>تاريخ الشحن</span></label>
                                        <input type="text" class="form-control" v-model="localData.shipping_date" placeholder="yyyy-mm-dd" >
                                        <span v-if="validShippingDate"><span v-if="lang==='en'">this feild is required</span> <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span><span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Invoice</span>  <span v-else-if="lang==='kr'">پسوولە</span>  <span v-else> الفاتورة</span></label>
                                        <input type="file" class="form-control-file" @change="onInvoiceChange">
                                        <span v-if="validInvoice"><span v-if="lang==='en'">this feild is required</span>  <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label> <span v-if="lang==='en'"> Attachments</span>  <span v-else-if="lang==='kr'">هاوپێچەکان</span>  <span v-else> المرفقات</span></label>
                                        <input type="file" class="form-control-file" multiple accept="image/jpeg,image/gif,image/png,application/pdf"  @change="onAttachmentsChange" >
                                        <span v-if="validAttachments"><span v-if="lang==='en'">this feild is required</span>  <span v-else-if="lang==='kr'">ئەم بوارە پێویستە</span> <span v-else>هذا الحقل مطلوب</span></span>
                                    </div>
                                </div>
                                <span v-if="errors"> <span v-if="lang==='en'">Not Sent</span> <span v-else-if="lang==='kr'">نەنێردراوە</span> <span v-else>لم يتم الإرسال</span></span>
                                <span v-if="success"> <span v-if="lang==='en'">Has Been Sent</span>  <span v-else-if="lang==='kr'">نێردراوە</span>  <span v-else>  تم الإرسال </span></span>
                                <btn :disabled="loading"  @Click="sendOffer" class="btn-sm btn-lg btn-block background-dark text-white text-center  text-uppercase rounded-0 padding-15px">
                                    <span v-if="loading"> <span v-if="lang==='en'">Sending</span>  <span v-else-if="lang==='kr'">دەنێردرێت</span>  <span v-else>  قيد الإرسال</span>...</span>
                                    <span v-else> <span v-if="lang==='en'">SEND Offer</span>  <span v-else-if="lang==='kr'">ئۆفەر بنێرە</span>  <span v-else>  إرسال العرض</span></span>
                                </btn>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
 // store
 import { useHome  } from "../../src/views/home/store"
  import { storeToRefs } from "pinia"

  const store = useHome()

  const { offices , loading , errors , success , contactInfoConst } = storeToRefs(store)
import {ref, watch , onMounted} from 'vue'
const localData=ref({})
const onInvoiceChange = (e)=>{
    localData.value.invoice = []
  localData.value.invoice.push(e.target.files[0])
//   localData.value.invoice = e.target.files || e.dataTransfer.files;
  console.log(localData.value.invoice);
}
const onAttachmentsChange = (e)=>{
    localData.value.attachments = []
    localData.value.attachments.push(e.target.files[0])
  console.log(localData.value.attachments);
}
watch(success , (newValue, oldValue)=>{
console.log("success" , oldValue)
    
   if(newValue === true){
    setTimeout(() => {
    console.log("localData.value" , localData.value)
    localData.value = {reason :'' ,mobile :'' , description :'' }
    console.log("localData.value" , localData.value)
        store.success = false
    }, 2000)
   
   }
})


const handelLang = (lang)=>{
    localStorage.setItem('lang' , lang)
    window.location.reload()

}
// const setDestinationId = (id)=>{
//     console.log(id)
//    localData.value.localData.office_destination_id = id 
// }
// const setOfficeId = (id)=>{
//     console.log(id)
//     localData.value.office_source_id = id 
// }
const validServiceType = ref(false)
const validSource = ref(false)
const validDestination = ref(false)
const validMember = ref(false)
const validStuffType = ref(false)
const validStuffWeight = ref(false)
const validStuffSize = ref(false)
const validLoadingPlace = ref(false)
const validDetails = ref(false)
const validClientName = ref(false)
const validCompanyName = ref(false)
const validEmail = ref(false)
const validMobile = ref(false)
const validShippingDate = ref(false)
const validInvoice = ref(false)
const validAttachments = ref(false)
const sendOffer = ()=>{
    console.log("localData",localData.value)
    if(localData.value.service_type.length == 0 || localData.value.office_source_id.length === 0 || localData.value.office_destination_id.length == 0 || localData.value.our_member.length ==0 || localData.value.stuff_type.length == 0 || localData.value.stuff_weight.length == 0
    || localData.value.stuff_size === 0 || localData.value.loading_place === 0 || localData.value.details === 0 || localData.value.client_name === 0 || localData.value.company_name === 0 || localData.value.email.length === 0 || localData.value.mobile.length === 0
    || localData.value.shipping_date.length === 0 || localData.value.invoice.length === 0 || localData.value.attachments.length === 0){
        if(localData.value.service_type.length == 0)
        validServiceType.value = true
        if(localData.value.office_source_id.length == 0)
        validSource.value = true
        if(localData.value.office_destination_id.length == 0)
        validDestination.value = true
        if(localData.value.our_member.length == 0)
        validMember.value = true
        if(localData.value.stuff_type.length == 0)
        validStuffType.value = true
        if(localData.value.stuff_weight.length == 0)
        validStuffWeight.value = true
        if(localData.value.stuff_size.length == 0)
        validStuffSize.value = true
        if(localData.value.loading_place.length == 0)
        validLoadingPlace.value = true
        if(localData.value.details.length == 0)
        validDetails.value = true
        if(localData.value.client_name.length == 0)
        validClientName.value = true
        if(localData.value.company_name.length == 0)
        validCompanyName.value = true
        if(localData.value.email.length == 0)
        validEmail.value = true
        if(localData.value.mobile.length == 0)
        validMobile.value = true
        if(localData.value.shipping_date.length == 0)
        validShippingDate.value = true
        if(localData.value.invoice.length == 0)
        validInvoice.value = true
        if(localData.value.attachments.length == 0)
        validAttachments.value = true
        
        
        
        
    }
    else {
        store.storeOffer(localData.value)
    }
   
}
// const toTop = ()=>{
//   window.scrollTo({
//           top: 0,
//           behavior: "smooth",
//         });
// }

const scTimer= ref(0);
        
const scY= ref(0);
const handleScroll= ()=> {
    if (scTimer.value) return;
    scTimer.value = setTimeout(() => {
    scY.value = window.scrollY;
    clearTimeout(scTimer.value);
    scTimer.value = 0;
    }, 100);
};
const email = ref('')
  const location = ref('')
  const phone = ref('')

  const lang = ref('')
  lang.value=  localStorage.getItem('lang') 
onMounted(async()=>{
    await store.fetchContactInfo() 
    email.value =  contactInfoConst.value.filter(item => item.slug === "email")[0].value
    location.value =  contactInfoConst.value.filter(item => item.slug === "address")[0].value
    phone.value =  contactInfoConst.value.filter(item => item.slug === "mobile-number")[0].value
    if (lang.value=== null){
        lang.value = 'en'
    }
   
  document.documentElement.lang = lang.value
  document.documentElement.dir = lang.value==='en' ? 'ltr' : 'rtl'
  console.log(lang)
    window.addEventListener('scroll', handleScroll);
    store.fetchOffice()
    localData.value = {
        service_type : '',
        office_source_id  : '',
        office_destination_id  : '',
        our_member  : '',
        stuff_type  : '',
        stuff_weight  : '',
        stuff_size  : '',
        loading_place  : '',
        details  : '',
        client_name  : '',
        company_name  : '',
        email  : '',
        mobile  : '',
        shipping_date  : '',
        invoice  : '',
        attachments  : '',
    }

})
watch(localData, () => {
    validServiceType.value = false
    validSource.value = false
    validDestination.value = false
    validMember.value = false
    validStuffType.value = false
    validStuffWeight.value = false
    validStuffSize.value = false
    validLoadingPlace.value = false
    validDetails.value = false
    validClientName.value = false
    validCompanyName.value = false
    validEmail.value = false
    validMobile.value = false
    validShippingDate.value = false
    validInvoice.value = false
    validAttachments.value = false

} , {deep:true})
watch(scY , ()=>{
    var element = document.getElementById("fixed-header-dark");
   if(scY.value>250){
        // console.log(scY.value)
        element.classList.add("fixed-header-dark");
   }


else{
    element.classList.remove("fixed-header-dark");
}
})
</script>


<style>
select.form-control{
    background-color: #e53935 !important;
}
.contact-modal form input{
    width:100%
}
.titleNav{
    justify-content:space-between ;
    align-items:center
}
.dropdown-menu.show {
    background: #e53935;
    color: white !important;
}
.dropdown-menu.show span {
    color: white !important;
}
button#dropdownMenuButton {
    display: none;
}
@media only screen and (max-width: 995px) {
    button#dropdownMenuButton {
    display: block;
    }
}

</style>